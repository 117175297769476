import instance from "../index";

class ClinicsAPI {
  static async getClinic(id) {
    let res = await instance.get("/clinics/" + id);
    return await res.data;
  }
  static async getClinics() {
    let res = await instance.get("/clinics");
    return await res.data;
  }
  static async editClinic(data) {
    let res = await instance.put("/clinics", data);
    return await res.data;
  }
}
export default ClinicsAPI;
